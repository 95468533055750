import {FC} from 'react'

interface IFieldErrorMessageProps {
  message: string
}

const FieldErrorMessage: FC<IFieldErrorMessageProps> = ({message}) => {
  return (
    <span className={'help u-txt-color-error'}>{message}</span>
  )
};

export default FieldErrorMessage;
