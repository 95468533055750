/* eslint-disable  @typescript-eslint/no-explicit-any */

import ElementPreview from './ElementPreview';
import Element from './Element';
import ElementPreviewPresenter from './ElementPreviewPresenter';

export default class ElementsPreviewPresenter {

  /**
   * Réorganise les données soumises dans un bloc afin d'afficher un composant récapitulattif en utilisant la colonne "presentation"
   *
   * @param elements : élements d'un blocs
   * @param submittedData : Valeurs soumises pour les élements d'un bloc
   */
  execute(elements: Element[] | null, submittedData: Record<string, any>): ElementPreview[] | null {

    const elementsRecapCollection: ElementPreview[] = [];

    elements?.map(element => {
      const presenter = new ElementPreviewPresenter().execute(element, element.id, submittedData)

      if (null !== presenter) {
        elementsRecapCollection.push(presenter)
      }
    })

    return elementsRecapCollection;
  }
}
