import {FC, FormEvent, InputHTMLAttributes} from 'react'
import {IFormFieldProps} from '../../IFormFieldProps';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id?: string
  classes: string,
  value?: string,
  defaultValue?: string
  onChange?: (e: FormEvent<HTMLInputElement>) => void,
  clearErrors: any
}

const Input: FC<IProps> = ({id, classes, label, onChange, value, defaultValue, register, clearErrors, error, required, ...rest}) => {
  const dispatch = useAppDispatch()

  const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
  const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
  const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
  const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  const handleChange = (element) => {
    clearErrors(id)

    if (undefined !== onChange) {
      onChange(element)
    } else {
      if (undefined !== id) {
        const data = [];
        data[id] = element.currentTarget.value;

        const saveUseCase = new SaveUseCase(new FormGateway())
        saveUseCase.execute(data).then(() => {
          const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
          elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
        })
      }
    }
  };

  if (undefined !== defaultValue && undefined !== id) {
    const response = new FormGateway().getCurrentValueForFormId()

    if (undefined === response.values[id]) {
      const data = [];
      data[id] = defaultValue;

      const saveUseCase = new SaveUseCase(new FormGateway())
      saveUseCase.execute(data).then(r => r)
    }
  }

  return (

    <div className={`${classes}  ${rest.readonly && "readonly-element"}`}>
      <div className={`form-floating ${error && 'error'} `}>
        <input className="form-control" value={value} defaultValue={defaultValue} {...rest}
               ref={register}
               required={required}
               readOnly={rest.readonly}
               onChange={handleChange} name={id} title={rest.help} />

        <label htmlFor={id}>{label}</label>

        {error?.message && <FieldErrorMessage message={error.message} />}
      </div>
    </div>

  )
};

export default Input;
