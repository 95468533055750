import React, {FunctionComponent, useState, useMemo} from 'react';

import Block from '../../../domain/Block/Block';
import BlockPreviewComponent from './BlockPreviewComponent';

// Redux
import {useAppSelector} from '../../../store/hook';
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../../gateway/Form/FormGateway';

type Props = {
  pageId: string,
  blockId: string
};

const BlockComponent: FunctionComponent<Props> = ({pageId, blockId}) => {
  const blocksStringOrObject = useAppSelector(state =>  state.blocks.all)

  const [block, setBlock] = useState<Block|null>(null)
  const [validationState, setValidationState] = useState<boolean>(false)

  useMemo(() => {
    const blocks = JSON.parse(blocksStringOrObject)
    const blockFromStore = (blocks.filter(block => block.id === blockId))[0]
    setBlock(blockFromStore)

    if (blockFromStore && undefined !== blockFromStore.condition) {
      const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
      setValidationState(validateConditionUseCase.execute(blockFromStore.condition))
    }

  }, [blocksStringOrObject, blockId])

  return (block &&
    <>
      {
        ((block.visible || (validationState && block.condition.actions.visible))) &&
          <BlockPreviewComponent key={block.id} block={block} state="validate" pageId={pageId} role="bo" />
      }
    </>
  )
}

export default BlockComponent
