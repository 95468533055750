/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {FC, useMemo, useState} from 'react';
import {FieldError} from 'react-hook-form';

import Element from '../../../domain/Element/Element'
import {EElementType} from '../../../domain/Element/EElementType';
import Input from './input/Input';
import RadiosList from './radios/RadiosList';
import ERadioType from './radios/ERadioType';
import MagicCheckbox from './checkbox/MagicCheckbox';
import Button from '../button/Button';
import Checkbox from './checkbox/Checkbox';
import Text from './text/Text'
import IconFactory from '../svg/IconFactory';
import RangeSlider from './range-slider/RangeSlider';
import DatePickerSimple from './datepicker/DatePickerSimple';
import DataList from './select/DataList';
import MyUploaderWithOptions from './dropzone/MyUploaderWithOptions';
import Sort from './sort/Sort';
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../../gateway/Form/FormGateway';
import ArrayChoice from './array-choice/ArrayChoice';
import {useAppSelector} from '../../../store/hook';
import {isDisabledCondition, isRequiredCondition, isVisibleCondition} from '../../util/validateCondition';
import Calculate from './Calculate/Calculate';
import ValidationSchema from '../../../validation/ValidationSchema';
import InputConvertNumberToWord from './converter/InputConvertNumberToWord';
import Presentation from './presentation/Presentation';
import SelectCustom from './select/SelectCustom';
import DataListChoice from './select/DataListChoice';
import ButtonDocusignIframe from './docusign/ButtonDocusignIframe';
import ButtonPrint from '../button/ButtonPrint';
import Download from '../svg/Download';
import Phone from './phone/Phone';
import ButtonCreateProspect from './prospect/ButtonCreateProspect';

interface IProps {
  blockId: string,
  element: Element,
  register: any,
  clearErrors: any,
  control: any,
  error: FieldError,
  formData: Record<string, unknown> | []
}

/**
 * Reçoit un élement flexible en entrée et retourne l'élement concerné en fonction du type
 *
 * @param blockId
 * @param element
 * @param register
 * @param error
 * @param formData
 * @constructor
 */
const ElementFactory: FC<IProps> = ({blockId, element, register, error, clearErrors, control, formData}) => {
  const [timestamp, setTimestamp] = useState<number | null>(null)
  const [validationState, setValidationState] = useState<boolean>(false)

  const elementsFromStoreString = useAppSelector(state => state.elements)

  if (null !== element.condition || '' !== element.calculate || '' !== element.reference) {
    const elementsFromStore = JSON.parse(elementsFromStoreString.currentElements)
    const currentIndexForElementId = elementsFromStore.findIndex(storedElement => storedElement.id === element.id)

    if (timestamp !== elementsFromStore[currentIndexForElementId].timestamp) {
      setTimestamp(elementsFromStore[currentIndexForElementId].timestamp)
    }
  }

  useMemo(() => {
    if (null !== element.condition) {
      const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
      setValidationState(validateConditionUseCase.execute(element.condition))
    }
  }, [timestamp, element.condition])



  if (isDisabledCondition(element, validationState)) {
    return null
  }

  if (!isVisibleCondition(element, validationState)) {
    return null
  }

  const attributes = element.attributes;
  const isRequired = isRequiredCondition(element, validationState)
  const schema = (new ValidationSchema().create(element, isRequired))
  const isDefaultValueBoolean = (attributes?.defaultValue === 'true') ? true : false

  switch (element.type) {
    case EElementType.INPUT_TEXT:
      return <Input classes={element.cssClasses}
                    label={attributes?.label}
                    register={register(schema)}
                    clearErrors={clearErrors}
                    type={'text'}
                    error={error}
                    id={element.id}
                    name={element.name}
                    placeholder={attributes?.label}
                    required={isRequired}
                    readonly={attributes?.readonly}
                    help={attributes?.help}
                    defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue} />
    case EElementType.INPUT_CONVERT_NUMBER_TO_WORD:
      return <InputConvertNumberToWord classes={element.cssClasses}
                                       label={attributes?.label}
                                       register={register}
                                       type={'text'}
                                       error={error}
                                       help={attributes?.help}
                                       id={element.id}
                                       name={element.name}
                                       placeholder={attributes?.label}
                                       required={isRequired}
                                       reference={element.reference}
                                       readonly={attributes?.readonly}
                                       timestamp={timestamp}
                                       element={element}
                                       defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue} />
    case EElementType.INPUT_IBAN:
      return <Input classes={element.cssClasses}
                    label={attributes?.label}
                    register={register(schema)}
                    clearErrors={clearErrors}
                    type={'text'}
                    error={error}
                    id={element.id}
                    name={element.name}
                    placeholder={attributes?.label}
                    required={isRequired}
                    readonly={attributes?.readonly}
                    defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                    help={attributes?.help}
      />
    case EElementType.INPUT_TEL:
      return <Phone classes={element.cssClasses}
                    label={attributes?.label}
                    register={register(schema)}
                    control={control}
                    schema={schema}
                    clearErrors={clearErrors}
                    type={'tel'}
                    error={error}
                    id={element.id}
                    name={element.name}
                    placeholder={attributes?.label}
                    required={isRequired}
                    defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                    help={attributes?.help}
                    readonly={attributes?.readonly}
      />
    case EElementType.INPUT_MAIL:
      return <Input classes={element.cssClasses}
                    label={attributes?.label}
                    register={register(schema)}
                    clearErrors={clearErrors}
                    type={'email'}
                    error={error}
                    readonly={attributes?.readonly}
                    id={element.id}
                    name={element.name}
                    placeholder={attributes?.label}
                    required={isRequired}
                    defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                    help={attributes?.help}
      />
    case EElementType.INPUT_RADIO:
      return <RadiosList options={attributes?.options}
                         id={element.id}
                         type={ERadioType.CIRCLE}
                         name={element.name} error={error}
                         classes={element.cssClasses}
                         register={register(schema)}
                         label={attributes?.label}
                         defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                         help={attributes?.help}
                         readonly={attributes?.readonly}
      />
    case EElementType.CHOICE:
      return <RadiosList options={attributes?.options}
                         id={element.id}
                         type={ERadioType.RECTANGLE}
                         name={element.name} error={error}
                         classes={element.cssClasses}
                         register={register(schema)}
                         label={attributes?.label}
                         defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                         help={attributes?.help}
                         readonly={attributes?.readonly}
      />
    case EElementType.INPUT_RADIO_SIMPLE:
      return <RadiosList options={attributes?.options}
                         id={element.id}
                         type={ERadioType.SIMPLE}
                         name={element.name} error={error}
                         classes={element.cssClasses}
                         register={register(schema)}
                         label={attributes?.label}
                         defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                         help={attributes?.help}
                         readonly={attributes?.readonly}
      />
    case EElementType.TOGGLE:
      return <MagicCheckbox name={element.name}
                            id={element.id}
                            register={register(schema)}
                            classes={element.cssClasses}
                            bold={attributes?.bold}
                            error={error}
                            label={attributes?.label}
                            checked={(formData && typeof formData[element.id] !== 'undefined') ? ('true' === formData[element.id] || ('boolean' === typeof formData[element.id] && formData[element.id])) : isDefaultValueBoolean}
                            help={attributes?.help}
                            readonly={attributes?.readonly}
      />
    case EElementType.BUTTON:
      return <Button classes={element.cssClasses}
                     label={attributes?.label}
                     type={attributes?.action}
                     position={attributes?.icon?.position}
                     icon={<IconFactory type={attributes?.icon?.type} />}
      />
    case EElementType.SELECT:
      return <SelectCustom options={attributes?.options}
                           id={element.id}
                           name={element.name}
                           classes={element.cssClasses} error={error}
                           register={register(schema)}
                           schema={schema}
                           required={isRequired}
                           label={attributes?.label}
                           multiple={attributes?.multiple}
                           control={control}
                           defaultValue={(formData && formData[element.id]) ? formData[element.id] : attributes?.defaultValue}
                           help={attributes?.help}
                           readonly={attributes?.readonly}
      />
    case EElementType.INPUT_CHECKBOX:
      return <Checkbox id={element.id}
                       label={attributes?.label}
                       classes={element.cssClasses}
                       name={element.name}
                       error={error}
                       register={register(schema)}
                       defaultChecked={(formData && typeof formData[element.id] !== 'undefined') ? ('true' === formData[element.id] || ('boolean' === typeof formData[element.id] && formData[element.id])) : isDefaultValueBoolean}
                       help={attributes?.help}
                       readonly={attributes?.readonly}
      />
    case EElementType.TEXT:
      return <Text classes={element.cssClasses}
                   content={attributes?.label} />
    case EElementType.RANGE:
      return <RangeSlider min={attributes?.min}
                          max={attributes?.max}
                          start={attributes?.start}
                          error={error}
                          label={attributes?.label}
                          name={element.name} register={register(schema)}
      />
    case EElementType.INPUT_DATE:
      return <DatePickerSimple classes={element.cssClasses}
                               label={attributes?.label}
                               id={element.id}
                               name={element.name}
                               minDateString={attributes?.minDate?.date}
                               maxDateString={attributes?.maxDate?.date}
                               error={error}
                               register={register(schema)}
                               defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                               help={attributes?.help}
                               readonly={attributes?.readonly}
      />
    case  EElementType.CHOICE_AUTOCOMPLETE:
      return <DataList options={attributes?.options}
                       id={element.id}
                       name={element.name}
                       classes={element.cssClasses}
                       label={attributes?.label} register={register(schema)}
                       defaultValue={formData[element.id]}
                       error={error}
                       help={attributes?.help}
                       readonly={attributes?.readonly}
      />
    case EElementType.INPUT_FILE_WITH_OPTIONS:
      return <MyUploaderWithOptions classes={element.cssClasses}
                                    label={attributes?.label}
                                    help={attributes?.help}
                                    options={attributes?.types}
                                    id={element.id}
                                    register={register}
                                    clearErrors={clearErrors}
                                    blockId={blockId}
                                    error={error}
                                    readonly={attributes?.readonly}
      />
    case EElementType.INPUT_SORT:
      return <Sort classes={element.cssClasses}
                   options={attributes?.options}
                   label={attributes?.label}
                   id={element.id}
                   name={element.name}
                   register={register(schema)}
                   defaultValue={formData[element.name]}
                   help={attributes?.help}
                   readonly={attributes?.readonly}
      />
    case EElementType.ARRAY_CHOICE:
      return <ArrayChoice classes={element.cssClasses}
                          options={attributes?.options}
                          label={attributes?.label}
                          id={element.id}
                          name={element.name}
                          register={register(schema)}
                          defaultValue={formData[element.id]}
                          help={attributes?.help}
                          readonly={attributes?.readonly}
      />
    case EElementType.CALCULATE:
      return <Calculate classes={element.cssClasses}
                        label={attributes?.label}
                        id={element.id}
                        name={element.name}
                        calculate={element.calculate}
                        register={register(schema)}
                        help={attributes?.help}
                        readonly={attributes?.readonly}
      />
    case EElementType.PRESENTATION:
      return <Presentation element={element}
      />
    case  EElementType.DATALIST_CHOICE:
      return <DataListChoice options={attributes?.options}
                             id={element.id}
                             datalistId={(attributes?.datalistId) ?? ''}
                       name={element.name}
                       classes={element.cssClasses}
                       label={attributes?.label} register={register(schema)}
                       defaultValue={formData[element.id]}
                       error={error}
                             help={attributes?.help}
      />
    case  EElementType.BUTTON_DOCUSIGN_IFRAME:
      return <ButtonDocusignIframe id={element.id} docusignId={attributes?.docusignId} label={attributes?.label} />
    case  EElementType.BUTTON_PRINT:
      return <ButtonPrint classes={element.cssClasses} defaultValue={attributes?.defaultValue} label={attributes?.label} position="right" icon={<Download color="#ffffff"/>}/>
    case  EElementType.BUTTON_CREATE_PROSPECT:
      return <ButtonCreateProspect />
    default:
      return <Input classes={element.cssClasses}
                    label={attributes?.label}
                    register={register(schema)}
                    clearErrors={clearErrors}
                    type={'text'}
                    error={error}
                    name={element.name}
                    id={element.id}
                    placeholder={attributes?.label}
                    required={isRequired}
                    defaultValue={(formData && formData[element.id]) ? String(formData[element.id]) : attributes?.defaultValue}
                    help={attributes?.help}
                    readonly={attributes?.readonly}
      />
  }
};

export default ElementFactory;
