import React, {FunctionComponent, useEffect, useState} from 'react';

import Block from '../../../domain/Block/Block';
import BlockComponent from './BlockComponent';

import {useAppDispatch, useAppSelector} from '../../../store/hook';
import FetchBlockGateway from '../../../gateway/Block/FetchBlockGateway';
import {setAllBlocks} from '../../../store/block/blocks';
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../../gateway/Form/FormGateway';
import FindAllBlocksUseCase from '../../../domain/Block/UseCase/FindAllBlocksUseCase';
import BlockValidationComponent from './BlockValidationComponent';
import {Link, useHistory} from 'react-router-dom';
import Next from '../svg/Next';
import BlockValidationSummaryComponent from './BlockValidationSummaryComponent';
import {useTranslation} from 'react-i18next';

type Props = {
  pageId: string,
  blockId: string | null
}

function getIndex(blocks, blockId) {
  if (null !== blockId) {
    const lastBlockSubmittedIndex = blocks.findIndex(block => block.id === blockId)

    return blocks.findIndex((block, index) => {
      let isValidate = true

      if (undefined !== block.condition) {
        const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
        isValidate = validateConditionUseCase.execute(block.condition)
      }

      return (block.visible || isValidate) && index > lastBlockSubmittedIndex
    })
  }

  return blocks.findIndex((block) => {
    let isValidate = true
    if (undefined !== block.condition) {

      const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
      isValidate = validateConditionUseCase.execute(block.condition)
    }

    return block.visible || (isValidate && block.condition?.actions.visible)
  })
}

const BlocksComponent: FunctionComponent<Props> = ({pageId, blockId}) => {
  const history = useHistory();
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [blocks, setBlocks] = useState<Block[] | null>(null)
  const role = localStorage.getItem('role')

  const storePages = useAppSelector(state => state.wizzardStep.pages)
  const pages = (typeof storePages === 'object') ? storePages : JSON.parse(storePages)
  const currentPageIndex = pages?.findIndex((p) => pageId === p.id)
  const nextPageIndex = currentPageIndex + 1
  const lastPageIndex = pages.length - 1

  useEffect(() => {
    setBlocks(null);
    const findAllBlocksUseCase = new FindAllBlocksUseCase(new FetchBlockGateway())

    findAllBlocksUseCase.execute(pageId).then(blocks => {
      if (blocks) {
        const nextBlockIndex = getIndex(blocks, blockId)

        if (nextBlockIndex !== -1) {
          for (let i = 0; i < nextBlockIndex; i++) {
            blocks[i].state = 'complete'
          }
          blocks[nextBlockIndex].state = 'progress'
        } else {
          history.push(`/form/${pages[nextPageIndex].id}`)
        }

        dispatch(setAllBlocks(JSON.stringify(blocks)))
        setBlocks(blocks)
      }
    })
  }, [dispatch, pageId, blockId])

  return (
    <>
      {(blocks &&
        <>
          {(role !== 'bo' &&
            (blocks.map((block, index) => (
                <>
                  {(role === 'bo' &&
                    <BlockValidationComponent key={index}
                                              pageId={pageId}
                                              blockId={block.id}
                    />
                  )}
                  {(role !== 'bo' &&
                    <BlockComponent key={index}
                                    pageId={pageId}
                                    blockId={block.id}
                    />
                  )}
                </>
              ))
            )
          )}
          {(role === 'bo' && currentPageIndex !== lastPageIndex &&
            <>
              {(blocks.map((block, index) => (
                  <BlockValidationComponent key={index}
                                            pageId={pageId}
                                            blockId={block.id}
                  />
                ))
              )}
              <div className={`card u-mxAuto flex-container end-lg`}>
                <Link to={`/form/${pages[nextPageIndex].id}`}
                      className="button button-primary button--large button--width-fit button-shadow-secondary u-pxl u-pys">{t('comment.next-step')} <Next /></Link>
              </div>
            </>
          )}
          {(role === 'bo' && currentPageIndex === lastPageIndex &&
            <BlockValidationSummaryComponent />
          )}
        </>
      )}
    </>
  )
}

export default BlocksComponent
