import Element from '../../domain/Element/Element'
import Block from '../../domain/Block/Block';

const isRequiredCondition = (element: Element, validationState: boolean): boolean => {
  if (element.condition && (validationState && false === element.condition.actions.required)) {
    return false
  }

  if (element.condition && (validationState && element.condition.actions.required)) {
    return true
  }

  return element.attributes?.required ?? false
}

const isDisabledCondition = (element: Element, validationState: boolean): boolean => {
  if (null === element.condition || undefined === element.condition.actions.disabled) {
    return element.attributes?.disabled ?? false
  }

  if (validationState) {
    return element.condition.actions.disabled
  }

  return element.attributes?.disabled ?? false
}

const isVisibleCondition = (element: Element, validationState: boolean): boolean => {
  return !(element.condition
    && ((validationState && false === element.condition.actions.visible) || (!validationState && element.condition.actions.visible)));
}

const isVisibleConditionBlock = (block: Block|null, validationState: boolean): boolean => {
  if (undefined !== block && null !== block) {
    return !(block.condition
      && ((validationState && false === block.condition.actions.visible) || (!validationState && block.condition.actions.visible)));
  }

  return true
}

export {isRequiredCondition, isDisabledCondition, isVisibleCondition, isVisibleConditionBlock}
