import ErrorInterface from './ErrorInterface';

export default class Error implements ErrorInterface {
  property: string
  message: string

  constructor(property, message) {
    this.property = property
    this.message = message
  }
}
