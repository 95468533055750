import React, {FunctionComponent} from 'react';

import logo from '../../../assets/images/logo-min.png';
import {Link} from 'react-router-dom';
import Logout from '../svg/Logout';
import {useTranslation} from 'react-i18next';

type Props = {
  reference?: number,
  cta?: boolean
};

const HeaderLightComponent: FunctionComponent<Props> = () => {
  const { t } = useTranslation()

  return (
    <div className="main-header u-mxl">
      <div className="flex-container middle-xs no-compensation">
        <h1 className="u-mb0 u-mrl">
          <a href="#">
            <img src={logo} alt="Société d'investissement" style={{maxWidth: '150px'}}/>
          </a>
        </h1>
      </div>
      <div className="flex-container middle-xs center-xs"/>
      <div className="flex-container middle-xs center-xs"/>
      <div className="flex-container middle-xs center-xs">
        <Link to="/logout" className="button button-secondary button--medium button--width-fit u-pxm">{t('button.logout')} <Logout/></Link>
      </div>
    </div>
  );
}

export default HeaderLightComponent;
