import {FC, InputHTMLAttributes} from 'react'

import {IFormFieldProps} from '../../IFormFieldProps';
import FormGateway from '../../../../gateway/Form/FormGateway';
import CalculateUseCase from '../../../../domain/Calculate/UseCase/CalculateUseCase';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  id: string
  classes: string,
  calculate?: string
}

const Calculate: FC<IProps> = ({id, classes, label, calculate, register, ...rest}) => {
  const dispatch = useAppDispatch()

  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  const calculateUseCase = new CalculateUseCase(new FormGateway())
  const value = calculateUseCase.execute(calculate) ?? ''

  const response = new FormGateway().getCurrentValueForFormId()

  if (undefined === response.values[id] || value !== response.values[id]) {
    const data = [];
    data[id] = value;

    const saveUseCase = new SaveUseCase(new FormGateway())
    saveUseCase.execute(data).then(() => {
      const elementsIdToRender: string[] = getElementsIdToRender(null, id, null, elementsWithReference)
      elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
    })

  }

  return (
    <div className={`${classes}  ${rest.readonly && "readonly-element"}`} title={rest.help}>
      <div className={`form-floating`}>
        <input className="form-control"
               type="text"
               value={value}
               name={id} readOnly
               ref={register}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>

  )
};

export default Calculate;
