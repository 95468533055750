import {FunctionComponent, useState} from 'react';

import GetUrl from '../../../../useCase/Docusign/GetUrl';
import FetchDocusignGateway from '../../../../gateway/Docusign/FetchDocusignGateway';
import Loader from '../../loader/Loader';
import * as React from 'react';

type Props = {
  id: string
  docusignId?: string
  label?: string
}

const ButtonDocusignIframe: FunctionComponent<Props> = ({id, docusignId, label}) => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [url, setUrl] = useState<string | undefined>(undefined)

  const handleClick = () => {
    if (undefined !== docusignId) {
      setLoading(true)
      new GetUrl(new FetchDocusignGateway()).execute(docusignId).then(response => {
        setLoading(false)
        setUrl(response)
      })
    }
  }

  return (
    <>
      {(!isLoading && (url === '' || undefined === url) &&
        <button type="button" onClick={handleClick}
                className="button button-primary button--large u-mxAuto">{label}</button>
      )}
      {('' !== url && undefined !== url &&
        <div className="col-md-12">
          <iframe id={id}
                  width="696"
                  height="500"
                  src={url}>
          </iframe>
        </div>
      )}
      {(isLoading &&
        <Loader />
      )}
    </>
  )
};

export default ButtonDocusignIframe;
