import React, { FunctionComponent } from 'react';

const Profil: FunctionComponent = () => {

  return (
    <div className="row">
      <h2 className="header center">Page profil</h2>
    </div>
  );
}

export default Profil;
