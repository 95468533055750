import {FunctionComponent, useEffect, useState} from 'react';
import {IArrayChoiceProps} from './IArrayChoiceProps';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';

const ArrayChoice: FunctionComponent<IArrayChoiceProps> = ({
                                                             options,
                                                             id,
                                                             defaultValue,
                                                             classes,
                                                             register,
                                                             name,
                                                             error,
                                                            ...rest
                                                           }) => {
  const dispatch = useAppDispatch()

  const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
  const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
  const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
  const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
  const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
  const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

  const [value, setValue] = useState(defaultValue ?? '');

  useEffect(() => {
    const itemSelected: HTMLElement = document.querySelector('.array-choice--item--selected') as HTMLElement
    if (value !== '' && itemSelected) {
      setTimeout(function () {
        itemSelected.click()
      }, 500)
    }
  }, [])

  const handleClick = (element) => {
    const index = element.currentTarget.attributes.getNamedItem('data-key').value
    const value = element.currentTarget.attributes.getNamedItem('data-value').value
    setValue(value)

    const data = [];
    data[id] = value;

    options?.options[index].options.map((opt, index) => {
      data[`${id}_${index}`] = opt.value;
    })

    const saveUseCase = new SaveUseCase(new FormGateway())
    saveUseCase.execute(data).then(() => {
      const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, id, elementsWithCalcule, elementsWithReference)
      elementsIdToRender.map(elementIdToRender => {
        dispatch(updateElementTimestamp({'id': elementIdToRender}))
      })
    })
  };

  return (
    <div className={`${rest.readonly && "readonly-element"}`}>
      <input type="hidden" name={id} id={name} ref={register} value={value}/>
      <div className={classes} title={rest.help}>
        <div className="array-choice">
          <div className="array-choice__header">
            {options?.header?.map((title, index) => {
              return <div className="array-choice--item__heading" key={index}>{title}</div>
            })}
          </div>
          <div className="array-choice__options">
            {options?.options?.map((option, index) => {
              return <div className={`array-choice--item ${option.value === value ? 'array-choice--item--selected' : ''}`} key={index} data-key={index} data-value={option.value} onClick={handleClick}>
                <div className="array-choice--item__heading">{option.label}</div>
                {option.options.map((optionChild, indexOption) => {
                  return <div className="array-choice--item__option" key={indexOption}>{optionChild.label}</div>
                })}
              </div>
            })}
          </div>
        </div>
        {error?.message && <FieldErrorMessage message={error.message} />}
      </div>
    </div>
  )
};

export default ArrayChoice;
