import FormGatewayInterface from '../../Form/FormGatewayInterface';

function calculate(fn) {
  return new Function('return ' + fn)();
}

export default class CalculateUseCase {
  public formGatewayInterface

  constructor(FormGatewayInterface: FormGatewayInterface) {
    this.formGatewayInterface = FormGatewayInterface
  }

  execute(mathematicalFormula: string | undefined): number | null {
    if (undefined === mathematicalFormula) {
      return null
    }

    const datas = this.formGatewayInterface.getCurrentValueForFormId()

    const inputIds = mathematicalFormula.match(/<input_id>(.*?)<\/input_id>/g)

    if (null === inputIds) {
      return null
    }

    let formula = mathematicalFormula
    inputIds.map(id => {
      const idWithoutTag = id.replaceAll(/<\/?input_id>/g,'')
      formula = formula.replace(`<input_id>${idWithoutTag}</input_id>`, datas.values[idWithoutTag])
    })

    try {
      const result = calculate(formula)
      return (Number(result)) ? Number(parseFloat(result).toPrecision(12)) : null
    } catch (e) {
      return null
    }
  }
}
