import React, {FunctionComponent, useEffect, useState} from "react";
import FolderComponent from "./FolderComponent";
import FetchFolderGateway from "../../../gateway/Folder/FetchFolderGateway";
import {FolderDetail} from "../../../domain/Folder/Folder";

type Props = {
  term: string,
  state?: string
}

const FoldersComponent: FunctionComponent<Props> = ({term, state}) => {
  const [folders, setFolders] = useState<FolderDetail[] | null>(null);

  useEffect(() => {
    (new FetchFolderGateway()).getFolders().then(folders => {
        if (null !== folders) {
          const foldersFiltered = folders.filter(folder => (folder.present)?.toLowerCase().includes(term) || (folder.present)?.toLowerCase().includes(term))

          if (state === 'dossiers-signes') {
            setFolders(foldersFiltered.filter(folder => folder.status === 'signed'))
          }

          if (state === 'dossiers-en-signature') {
            setFolders(foldersFiltered.filter(folder => folder.status === 'signature'))
          }

          if (state === 'dossiers-en-cours') {
            setFolders(foldersFiltered.filter(folder => (folder.status === 'ongoing' || folder.status === 'mission_validated' || folder.status === 'mission_validation' || folder.status === 'adequacy_report_validated' || folder.status === 'adequacy_report_validation')))
          }

          if (state === 'dossiers-abandonnes') {
            setFolders(foldersFiltered.filter(folder => (folder.status === 'canceled' || folder.status === 'rejected')))
          }

          if (state === undefined) {
            setFolders(foldersFiltered)
          }
        }
      }
    )
  }, [term, state])

  return (
    <div className="flex-container">
        <div className="col-md-12">
          {folders?.map((FolderDetail) => {
            return <FolderComponent FolderDetail={FolderDetail} key={FolderDetail.id}/>
          })}
        </div>
    </div>
  )
}

export default FoldersComponent;

