import React, { FunctionComponent } from 'react';
import {useHistory} from "react-router";
import {useTranslation} from 'react-i18next';

import {FolderDetail} from "../../../domain/Folder/Folder";
import storage from "../../util/storage";
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway';
import GetDetailAndStoreItUseCase from '../../../useCase/Folder/GetDetailAndStoreItUseCase';
import LocalStorageGateway from '../../../gateway/Form/LocalStorageGateway';

type Props = {
  FolderDetail: FolderDetail
}

const FolderComponent: FunctionComponent<Props> = ({FolderDetail}) => {
  const history = useHistory()
  const { t } = useTranslation()

  function handleEdit() {
    storage.setFolderId(FolderDetail.id)
    storage.setDossierState(FolderDetail.status)
    const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

    getDetailAndStoreItUseCase.execute(FolderDetail.id).then((response) => {
      if (response !== null) {
        history.push(response.url)
      }
    })
  }

  return (
    <>
      <div className="list__line" key={FolderDetail.id}>
        <div>
          <div className="h5 u-mb0">{FolderDetail.present} ({t(`folder.state.${FolderDetail.status}`)})</div>
          <div className="u-txt-size-s">{t('common.folder')} n° {FolderDetail.id} {(FolderDetail.advisor?.external_id && <>({FolderDetail.advisor?.external_id})</>)} </div>
        </div>
        <div>
          <button onClick={() => handleEdit()} className="edit-file button button-primary--outline button--medium button--width-fit" data-id={FolderDetail.id}>
            {t('button.modify')}
          </button>
        </div>
      </div>
    </>
  )
}

export default FolderComponent;
