import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Block from '../../../domain/Block/Block';
import Element from '../../../domain/Element/Element';
import ElementFactory from '../elements/ElementFactory';
import FetchElementGateway from '../../../gateway/Element/FetchElementGateway';
import PostUSeCase from '../../../domain/Form/PostUseCase'
import FormGateway from '../../../gateway/Form/FormGateway';
import FindUseCase from '../../../domain/Form/FindUseCase';
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway';

// Redux
import {useAppDispatch, useAppSelector} from '../../../store/hook'
import {updateProgressValueById, updateNextPageId} from '../../../store/wizzard/wizzardStep';
import {updateBlock} from '../../../store/block/blocks';
import {setCurrent} from '../../../store/element/elements';

import Back from '../svg/Back';
import storage from '../../util/storage';
import Loader from '../loader/Loader';
import {setTimestamp} from '../../../store/header/header';

type Props = {
  pageId: string,
  block: Block,
};

const BlockFormComponent: FunctionComponent<Props> = ({pageId, block}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const {t} = useTranslation()

  const {register, handleSubmit, setError, errors, clearErrors, control} = useForm()
  const [elements, setElements] = useState<Element[] | null>(null);
  const [formData, setFormData] = useState<Record<string, unknown> | [] | null>(null);
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [genericError, setGenericError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false)

  const storeWizzardStepNextPageId = useAppSelector(state => state.wizzardStep.nextPageId)
  const storeBlocksVisible = useAppSelector(state => state.blocks.blocksVisible)
  const storeBlocksVisibleLength = useAppSelector(state => state.blocks.blocksVisibleLength)

  const blockRef = useRef<HTMLFormElement>(null)

  const handleClick = () => {
    if (null !== blockRef.current) {
      window.scrollTo({top: (blockRef?.current?.offsetTop || 0) - 100, behavior: 'smooth'})
      setIsScrolled(true)
    }
  }

  useEffect(() => {
    (new FetchElementGateway()).getElementsByBlockId(block.id).then(elements => {
      dispatch(setCurrent({
        'currentElements'             : JSON.stringify(elements),
        'currentElementsWithCondition': JSON.stringify(elements?.filter(element => null !== element.condition)),
        'currentElementsWithCalcule'  : JSON.stringify(elements?.filter(element => '' !== element.calculate)),
        'currentElementsWithReference': JSON.stringify(elements?.filter(element => '' !== element.reference))
      }));

      setElements(elements)

      const findUseCase = new FindUseCase(new FormGateway());
      findUseCase.execute().then(data => {
        setFormData((data) ?? [])

        if (!isScrolled) {
          handleClick()
        }
      })
    })
  }, [dispatch, pageId, block.id]);

  const onSubmit = (data, e) => {
    setLoading(true)
    let hasErrors = false

    if (block.id === '48ea4516-ae8a-4030-9aad-661295b74c01') {
      const inputFilesList = document.querySelectorAll('input[type=file]');
      const uploadersWithMultipleOptions = ["64b9a000-ce7b-4ce6-9ba8-d5ef9bed7032"];

      inputFilesList.forEach(function(inputFile) {
        if (!storage.getReceipts(inputFile.id) && inputFile.id !== '09ae70ed-3c78-403d-9993-3ca7b14ae147' && !(inputFile.id in uploadersWithMultipleOptions)) {
          hasErrors = true
          setError(inputFile.id, {
            type: "manual",
            message: "Merci de téléverser ce document obligatoire"
          })
        }
      });

      uploadersWithMultipleOptions.map(id => {
        if((!storage.getReceipts(id))){
          hasErrors = true;
          setError(id, {
            type: "manual",
            message: "Merci de téléverser ce document obligatoire"
          })
        }
      });
    }

    if (block.id === '968757f1-390b-4d94-9773-6b18c57a2f1e') {
      if (!storage.getReceipts('1715a908-08e3-4dfd-927a-57ba84d8fbea')) {
        hasErrors = true
        setError('1715a908-08e3-4dfd-927a-57ba84d8fbea', {
          type: "manual",
          message: "Merci de téléverser ce document obligatoire"
        })
      }

      if (!storage.getReceipts('2fbfde04-4a3a-4b99-b458-c7d3c63d460b')) {
        hasErrors = true
        setError('2fbfde04-4a3a-4b99-b458-c7d3c63d460b', {
          type: "manual",
          message: "Merci de téléverser ce document obligatoire"
        })
      }
    }

    // Mise en place d'une condition temporaire
    // Pour vérifier si le téléphone du cosous
    // est différent de celui non cosous
    if (data['d1f92caa-69e5-4042-995f-298c3e0bc802']) {
      const formDatasJSON = localStorage.getItem('form');

      if (formDatasJSON != null) {
        const formDatasSaved = JSON.parse(formDatasJSON)
        if (
          formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003']
          && formDatasSaved['06fc9276-9279-11eb-a8b3-0242ac130003'] === data['d1f92caa-69e5-4042-995f-298c3e0bc802']
        ) {
          hasErrors = true
          setError('d1f92caa-69e5-4042-995f-298c3e0bc802', {
            type: "manual",
            message: "Pour pouvoir signer la souscription en ligne, il est nécessaire d'avoir 2 numéros de téléphones différents"
          })
        }
      }
    }

    if (data['6cefd0ca-57b0-4bc5-98a8-63b1e79e0867']
        && data['507df8da-3685-4a95-8d43-54b8893f698c']
        && data['43d3ea2a-c452-4367-bd27-9c0e8908113d']
        && data['720e5818-c62d-4c76-99a0-41bc1f5330ad']
        && data['17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf']) {

      const value1 = parseInt(data['6cefd0ca-57b0-4bc5-98a8-63b1e79e0867'])
      const value2 = parseInt(data['507df8da-3685-4a95-8d43-54b8893f698c'])
      const value3 = parseInt(data['43d3ea2a-c452-4367-bd27-9c0e8908113d'])
      const value4 = parseInt(data['720e5818-c62d-4c76-99a0-41bc1f5330ad'])
      const value5 = parseInt(data['17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf'])
      const total = value1 + value2 + value3 + value4 + value5

      if (total !== 100) {
        hasErrors = true
        setError('6cefd0ca-57b0-4bc5-98a8-63b1e79e0867', {
          type: "manual",
          message: "La somme des champs doit faire 100%"
        })
        setError('507df8da-3685-4a95-8d43-54b8893f698c', {
          type: "manual",
          message: "La somme des champs doit faire 100%"
        })
        setError('43d3ea2a-c452-4367-bd27-9c0e8908113d', {
          type: "manual",
          message: "La somme des champs doit faire 100%"
        })
        setError('720e5818-c62d-4c76-99a0-41bc1f5330ad', {
          type: "manual",
          message: "La somme des champs doit faire 100%"
        })
        setError('17ea0440-77d8-4eb0-9b4e-fcf26ecedbdf', {
          type: "manual",
          message: "La somme des champs doit faire 100%"
        })
      }
    }

    if (hasErrors) {
      document.querySelector('.u-txt-color-error')?.scrollIntoView({ behavior: 'smooth', block: "center" })
      setLoading(false)
      return false
    }

    const postUseCase = new PostUSeCase(new FormGateway(), new FetchFolderGateway());
    postUseCase.execute(data, block.id)
      .then(() => {
        e.target.closest('.box-elevations').classList.add('box-elevations--closed')

        setTimeout(() => {
          const blockCompleteBeforeModifyBlock = (JSON.parse(storeBlocksVisible)).findIndex(blockFromStore => blockFromStore.id === block.id)
          const progressPourcentage = ((blockCompleteBeforeModifyBlock + 1) / storeBlocksVisibleLength) * 100
          dispatch(updateBlock({'id': block.id, 'state': 'complete', 'stateNext': 'progress'}))
          dispatch(updateProgressValueById({'id': pageId, 'progress': progressPourcentage}))
          dispatch(setTimestamp({'timestamp': Date.now()}))

          if (progressPourcentage === 100) {
            dispatch(updateNextPageId({'id': storeWizzardStepNextPageId}))
            history.push(`/form/${storeWizzardStepNextPageId}`)
          }
        }, 800)
      }).catch(() => {
        setLoading(false)
        setGenericError(t('error.technical-error'))
    })
  };
  const onError = (errors) => {
    const firstKey = Object.keys(errors)[0]

    if ((errors[firstKey].ref).type === 'hidden') {
      document.querySelector('.errors-custom-generated-for-hidden')?.remove()
      const createErrorElement = document.createElement('span')
      createErrorElement.classList.add('help', 'u-txt-color-error')
      createErrorElement.innerText = errors[firstKey].message;
      const createErrorElementWrapper = document.createElement('div')
      createErrorElementWrapper.classList.add('col-md-12', 'errors-custom-generated-for-hidden')
      createErrorElementWrapper.appendChild(createErrorElement);
      ((errors[firstKey].ref).parentNode).appendChild(createErrorElementWrapper)
    }

    if (undefined !== errors[firstKey].ref && errors[firstKey].ref.scrollIntoView != undefined) {
      (errors[firstKey].ref).parentNode.scrollIntoView({ behavior: 'smooth', block: "center" })
    }

    setLoading(false)
  };

  return (<>
    {elements && formData &&
    <form onSubmit={handleSubmit(onSubmit, onError)} className="form--grey-7" ref={blockRef} noValidate={true}>
      <div className={'flex-container'}>
        {elements?.map((element, index) => (
          <ElementFactory key={index}
                          blockId={block.id}
                          element={element}
                          register={register}
                          clearErrors={clearErrors}
                          formData={formData}
                          control={control}
                          error={errors[element.id]} />
        ))}
      </div>
      <div className="flex-container center-md u-mtl u-relative">
        {genericError !== '' &&
          <p className="notification notification--error">{genericError}</p>
        }

        {errors.files && <p className="notification notification--error u-pointer" onClick={() => clearErrors('files')}>{errors.files.message}</p>}

        {block.id !== 'e497fd89-4b40-4e99-9e7a-b2f63ec9d669' && block.id !== 'cecfa1cc-748f-4998-a20c-8a909a6a6781' && block.id !== 'c2fc93ff-1e3e-499e-a060-a111668601b5' && undefined === errors.files &&
          <>
            <div className="col-md-12">
              {(!isLoading &&
                <button type="submit" className="button button-primary button--large u-mxAuto">{t('button.keep-on')}</button>
              )}
              {(isLoading &&
                <Loader />
              )}
            </div>
            <div className="illustration illustration--profile" dangerouslySetInnerHTML={{ __html: block.icon }}></div>
          </>
        }
        {block.id === 'e497fd89-4b40-4e99-9e7a-b2f63ec9d669' && undefined === errors.files &&
          <>
            <div className="illustration" dangerouslySetInnerHTML={{ __html: block.icon }}></div>
          </>
        }
        {block.id === 'cecfa1cc-748f-4998-a20c-8a909a6a6781' && undefined === errors.files &&
          <>
            <div className="col-md-12">
              <div className="col-md-12">
                {(!isLoading &&
                  <button type="submit" className="button button-primary button--large u-mxAuto">{t('button.sign')}</button>
                )}
                {(isLoading &&
                  <Loader />
                )}
              </div>
            </div>
            <div className="illustration" dangerouslySetInnerHTML={{ __html: block.icon }}></div>
          </>
        }
        {block.id === 'c2fc93ff-1e3e-499e-a060-a111668601b5' && undefined === errors.files &&
        <>
          <div className="col-md-12">
            {(!isLoading &&
              <button type="submit" className="button button-primary button--large u-mxAuto">{t('button.begin')}</button>
            )}
            {(isLoading &&
              <Loader />
            )}
          </div>
          <div className="illustration illustration--profile" dangerouslySetInnerHTML={{ __html: block.icon }}></div>
        </>
        }
      </div>
    </form>
    }
    </>)
}

export default BlockFormComponent;
