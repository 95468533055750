import {FC, useState} from "react";
import Nouislider from "nouislider-react";

import {IFormFieldProps} from "../../IFormFieldProps";
import FieldErrorMessage from "../messages/FieldErrorMessage";

interface IProps extends IFormFieldProps {
    id?: string
    min?: number,
    max?: number,
    start?: number,
    name: string,
    initialValue?: number
}

const RangeSlider: FC<IProps> = ({id, min, max, start, label, register, initialValue, name, error, ...rest}) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    start = (start) ?? 0

    return (
        <div className={`col-md-12  ${rest.readonly && "readonly-element"}`} title={rest.help}>
            <input className={"checkbox-toggle"} name={id} id={name} ref={register} value={value}/>
            <div className="h5">{label}</div>
            <Nouislider
                className={"slider-range--no-pips-label u-mtm noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr"}
                step={1}
                connect={[true, false]}
                tooltips={true}
                start={[start]}
                onSlide={handleChange}
                range={{
                    min: [min],
                    max: [max]
                }}
                pips={{mode: "positions", values: [12.5, 25, 37.5, 50, 62.5, 75, 87.5], density: 100}}
            />
            <div className="flex-container between-xs no-compensation u-mts">
                <span>{min}</span>
                <span>{max} +</span>
            </div>
            {error?.message && <FieldErrorMessage message={error.message}/>}
        </div>
    )
};

export default RangeSlider;

