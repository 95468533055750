import Caller from "../../domain/Api/Caller";
import envVariable from "../../presentation/util/envVariable";
import FolderGatewayInterface from "../../domain/Folder/FolderGatewayInterface";
import {Folder, FolderDetail} from "../../domain/Folder/Folder";
import storage from "../../presentation/util/storage";

export default class FetchFolderGateway implements FolderGatewayInterface{
  async createFolder(formId: string, clientId: number): Promise<Folder|null> {

    return Caller.executePost(`${envVariable('REACT_APP_API_URL')}/dossiers`,{
      formId: formId,
      clientId: clientId,
      submittingBlockId: null,
      values: []
    })
      .then(response => {
        if (!response.dossier) {
          return null
        }

        return new Folder(
          response.dossier.id,
          response.dossier.form_id,
          response.dossier.client_id,
          response.dossier.advistor_id,
          response.dossier.last_submitted_block_id,
          response.dossier.values,
          response.dossier.direct,
          response.dossier.receipts,
          response.dossier.authorized_clients
        )
      })
  }

  async updateFolder(data: Array<string>, BlockId: string): Promise<Folder|null> {
      const values = Object.entries(data).map((value) => {
        if (value) {
          return ({
            elementId: value[0],
            value: typeof value[1] === 'boolean' ? String(value[1]) : value[1]
          })
        }
      })

      return Caller.executePut(`${envVariable('REACT_APP_API_URL')}/dossiers/${storage.getFolderId()}/values`, {
        submittingBlockId: BlockId,
        values: values
      })
        .then(response => {
          if (response === undefined) {
            return null
          }

          return new Folder(
            response.dossier.id,
            response.dossier.form_id,
            response.dossier.client_id,
            response.dossier.advistor_id,
            response.dossier.last_submitted_block_id,
            response.dossier.values,
            response.dossier.direct,
            response.dossier.receipts,
            response.dossier.authorized_clients,
            response.dossier.status
          )
        })
  }

  async getFolders(): Promise<FolderDetail[]|null> {
    return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers`, {})
      .then(data => {

        if (!data) {
          return null
        }

        return (data.dossiers).map((dossier) => {
          return new FolderDetail(
            dossier.id,
            dossier.creation_date,
            dossier.validation_date,
            dossier.signature_date,
            dossier.status,
            dossier.client,
            dossier.detail,
            dossier.advisor,
            dossier.present
          )
        })
      })
  }

  async getFolder(folderId: string): Promise<Record<string, unknown>|null> {
    return await Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/dossiers/${folderId}`, {})
    .then(dossier => {

      if (!dossier) {
        return null
      }

      return dossier
    })
  }
}
