import Element from '../../domain/Element/Element';

const getElementsIdToRender = (elementsWithCondition:Element[]|null, currentElementId:string, elementsWithCalcule:Element[]|null, elementsWithReference: Element[]|null): string[] => {
  const elementsIdToRender: string[] = []

  if (null !== elementsWithCondition) {
    elementsWithCondition.map(elementWithCondition => {
      if (null !== elementWithCondition.condition) {
        (elementWithCondition.condition.rules).map(rule => {
          if (rule.input_id === currentElementId) {
            elementsIdToRender.push(elementWithCondition.id)
          }
        })
      }
    })
  }

  if (null !== elementsWithCalcule) {
    elementsWithCalcule.map(elementWithCalcule => {
      if (undefined !== elementWithCalcule.calculate && '' !== elementWithCalcule.calculate) {
        elementWithCalcule.calculate.match(/<input_id>(.*?)<\/input_id>/g)?.map((input) => {
          let inputId = input.replace(/<\/?input_id>/g,'')
          inputId = inputId.replace(/_1/g,'')
          inputId = inputId.replace(/_0/g,'')

          if (inputId === currentElementId) {
            elementsIdToRender.push(elementWithCalcule.id)
          }
        })
      }
    })
  }

  if (null !== elementsWithReference) {
    elementsWithReference.map(elementWithReference => {
      if (elementWithReference.reference === currentElementId) {
        elementsIdToRender.push(elementWithReference.id)
      }
    })
  }

  return elementsIdToRender
}

export default getElementsIdToRender;
