import React, {FunctionComponent} from 'react';

import Page from '../../../domain/Page/Page';

import {useAppDispatch} from '../../../store/hook';
import {updateNextPageId} from '../../../store/wizzard/wizzardStep';
import storage from '../../util/storage';

type Props = {
  page: Page,
  currentPageId: string,
  number: string,
  progress: number,
};

function handleClickStep(dispatch, id) {
  dispatch(updateNextPageId({'id': id}))
  window.location.href = `/form/${id}`
}

const WizzardStepComponent: FunctionComponent<Props> = ({page, currentPageId, number, progress}) => {
  const dispatch = useAppDispatch()

  const dossierState = storage.getDossierState()

  const isComplete = page.id !== currentPageId && progress === 100
  const wizzardActive = page.id === currentPageId ? 'wizzard__step--active' : ''
  const wizzardComplete = (isComplete) ? ('signed' !== dossierState && 'signature' !== dossierState) ? 'wizzard__step--complete' : 'wizzard__step--complete-signed' : ''

  let titleElement = <>
    <div className="step__number">{number}</div>
    <div className="step__title">{page.title}</div>
  </>

  if (isComplete && 'signed' !== dossierState && 'signature' !== dossierState) {
    titleElement = <div onClick={() => handleClickStep(dispatch, page.id)}>
      {titleElement}
    </div>
  }

  return (
      <div className={`wizzard__step ${wizzardActive} ${wizzardComplete}`}>
        <div className="step__progression">
          <div className="progress" style={{width: `${progress}%`}}></div>
        </div>
        {titleElement}
      </div>

  );
}

export default WizzardStepComponent;
