import React, {FunctionComponent, useEffect, useState} from 'react'

import BlockState from './BlockState'
import ModifyComponent from './call-to-action/ModifyComponent'
import Block from '../../../domain/Block/Block'

import FormGateway from '../../../gateway/Form/FormGateway'
import FindUseCase from '../../../domain/Form/FindUseCase'
import FetchElementGateway from '../../../gateway/Element/FetchElementGateway'
import ElementsPreviewPresenter from '../../../domain/Element/ElementsPreviewPresenter'
import ElementPreview from '../../../domain/Element/ElementPreview'
import ValidationComponent from './call-to-action/ValidationComponent'

type Props = {
  pageId: string, block: Block,
  state: string,
  role: string
}

const BlockPreviewComponent: FunctionComponent<Props> = ({pageId, block, state, role}) => {
  const stateClass = BlockState.getStateClass(state)
  const [formData, setFormData] = useState<ElementPreview[] | null>([])

  useEffect(() => {
    if (state !== 'disable') {
      (new FetchElementGateway()).getElementsByBlockId(block.id).then(elements => {
        const findUseCase = new FindUseCase(new FormGateway())
        findUseCase.execute().then(data => {
          if (data !== null) {
            setFormData(new ElementsPreviewPresenter().execute(elements, data))
          }
        })
      })
    }
  }, [pageId, block.id, state])

  let initialStateAnimationClass = 'slideDown'
  if (block.state === 'validate' || block.state === 'complete') {
    initialStateAnimationClass = 'slideUp'
  }

  const [stateAnimationClass, setStateAnimationClass] = useState<string>(initialStateAnimationClass)

  setTimeout(() => {
    if (block.state === 'validate' || block.state === 'complete') {
      setStateAnimationClass('slideDown')
    }
  }, 1)

  return (
    <div className={`${stateAnimationClass}`}>
      <div className={`card ${stateClass} u-mxAuto`}>
        <div className="card__first">
          <h3 className="card__title">{block.title}</h3>
          <div className="card__content flex-container">
            <p className="col-md-12">
              {formData != null && formData.map((data, index) => {
                return (data.presentation && <span key={index}>{data.presentation} <br /> </span>)
              })}
            </p>
          </div>
        </div>
        {(role === 'bo' &&
          <>
            <ValidationComponent blockId={block.id} />
          </>
        )}
        {(role !== 'bo' &&
            <ModifyComponent blockId={block.id} pageId={pageId} />
        )}
      </div>
    </div>
  )
}

export default BlockPreviewComponent
