export enum EElementType {
    INPUT_TEXT = 'input_text',
    INPUT_IBAN = 'input_iban',
    INPUT_RADIO = 'input_radio',
    CHOICE_AUTOCOMPLETE = 'choice_autocomplete',
    INPUT_RADIO_SIMPLE = 'input_radio_simple',
    CHOICE = "choice",
    TOGGLE = "toggle",
    BUTTON = "button",
    SELECT = 'select',
    INPUT_TEL = 'input_tel',
    INPUT_MAIL = 'input_mail',
    INPUT_DATE = 'input_date',
    INPUT_CHECKBOX = 'input_checkbox',
    RANGE = 'range',
    TEXT = 'text',
    INPUT_FILE_WITH_OPTIONS = 'receipt',
    INPUT_FILE = 'input_file',
    INPUT_SORT = 'ordonnancement',
    ARRAY_CHOICE = 'array_choice',
    CALCULATE = 'calculate',
    INPUT_CONVERT_NUMBER_TO_WORD = 'convert_number_to_word',
    PRESENTATION = 'presentation',
    DATALIST_CHOICE = 'datalist_choice',
    BUTTON_DOCUSIGN_IFRAME = 'button_docusign_iframe',
    BUTTON_PRINT = 'button_print',
    BUTTON_CREATE_PROSPECT = 'button_create_prospect'
}
