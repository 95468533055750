import { combineReducers } from '@reduxjs/toolkit'

import wizzardStepReducer from './wizzard/wizzardStep'
import blocksReducer from './block/blocks'
import elementsReducer from './element/elements'
import headerReducer from './header/header'

const rootReducer = combineReducers({
  wizzardStep: wizzardStepReducer,
  blocks: blocksReducer,
  elements: elementsReducer,
  header: headerReducer,
})

export default rootReducer
