/* eslint-disable  @typescript-eslint/no-explicit-any */

import {EElementType} from './EElementType';
import ElementPreview from './ElementPreview';
import Element from './Element';
import ValidateConditionUseCase from '../Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../gateway/Form/FormGateway';
import {isDisabledCondition, isVisibleCondition} from '../../presentation/util/validateCondition';

export default class ElementPreviewPresenter {

  execute(element: Element, elementId: string, submittedData: Record<string, any>): ElementPreview | null {

    let valueEntered = String(submittedData[elementId]);

    const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
    const validationState = validateConditionUseCase.execute(element.condition)

    if (!valueEntered || isDisabledCondition(element, validationState) || !isVisibleCondition(element, validationState)) {
      return null
    }

    const fieldType: EElementType = <EElementType>element.type;
    const attributes = element.attributes;

    if ([
      EElementType.INPUT_TEXT,
      EElementType.INPUT_IBAN,
      EElementType.INPUT_TEL,
      EElementType.INPUT_MAIL,
      EElementType.CALCULATE,
      EElementType.INPUT_CONVERT_NUMBER_TO_WORD,
      EElementType.RANGE,
      EElementType.PRESENTATION
    ].includes(fieldType)) {
      if (attributes?.presentation) {
        const presentationString = ('empty' !== attributes?.presentation) ? `${attributes?.presentation} : ` : ''
        valueEntered = `${presentationString} ${valueEntered}`;
      }

      if ('' !== attributes?.presentation) {
        return new ElementPreview(valueEntered, element.name)
      }

      return null
    }

    if (EElementType.INPUT_DATE == fieldType) {
      const presentationDate = `${element.attributes?.presentation} ${valueEntered}`;

      if ('' !== attributes?.presentation) {
        return new ElementPreview(presentationDate, element.name)
      }
    }

    if ([
      EElementType.CHOICE_AUTOCOMPLETE,
      EElementType.INPUT_RADIO,
      EElementType.CHOICE,
      EElementType.TOGGLE,
      EElementType.SELECT,
      EElementType.INPUT_CHECKBOX,
      EElementType.INPUT_RADIO_SIMPLE
    ].includes(fieldType)) {
      const selectedOption = element.attributes?.options?.find(option => option.value == valueEntered) ?? null

      if (selectedOption != null && '' !== attributes?.presentation) {
        return new ElementPreview(selectedOption.presentation, element.name)
      }

      return null
    }

    if (EElementType.INPUT_SORT == fieldType && submittedData[element.name]) {
      element.attributes?.options?.map((option) => {
        if (option.value === submittedData[element.name][0] && '' !== attributes?.presentation) {
          return new ElementPreview(`${option.presentation} ...`, element.name)
        }
      })
    }

    return null
  }
}
