import Element from '../../domain/Element/Element';
import ElementGatewayInterface from '../../domain/Element/ElementGatewayInterface';
import envVariable from '../../presentation/util/envVariable';
import Caller from '../../domain/Api/Caller';

export default class FetchElementGateway implements ElementGatewayInterface {

  async getElementsByBlockId(blockId: string): Promise<Element[] | null> {
    return Caller.executeGet(`${envVariable('REACT_APP_API_URL')}/${envVariable('REACT_APP_LOCALE')}/blocks/${blockId}/elements`, {})
      .then(data => {
        if (!data) {
          return null
        }

        return (data.elements).map((item) => {
          let type = item.type
          let attributes = item.attributes
          let condition = item.condition
          let calculate = ''
          let reference = ''

          // Mock tableau de démembrement
          if (item.id === '66bce220-e3a1-4284-82f0-2499a485e255') {
            type = 'array_choice'
            attributes = {
              "label": "Tableau de démembrement viager",
              "readonly": false,
              "disabled": true,
              "required": true,
              "multiple": false,
              "options": {
                header: ['Année','Usufruit','Nue-propriété'],
                options: [
                  {value: '3', label: '3 ans', options: [{label: '13%', value: '13'}, {label: '87%', value: '87'}], presentation: '3 ans'},
                  {value: '4', label: '4 ans', options: [{label: '17%', value: '17'}, {label: '83%', value: '83'}], presentation: '4 ans'},
                  {value: '5', label: '5 ans', options: [{label: '21%', value: '21'}, {label: '79%', value: '79'}], presentation: '5 ans'},
                  {value: '6', label: '6 ans', options: [{label: '24%', value: '24'}, {label: '76%', value: '76'}], presentation: '6 ans'},
                  {value: '7', label: '7 ans', options: [{label: '27%', value: '27'}, {label: '73%', value: '73'}], presentation: '7 ans'},
                  {value: '8', label: '8 ans', options: [{label: '29%', value: '29'}, {label: '71%', value: '71'}], presentation: '8 ans'},
                  {value: '9', label: '9 ans', options: [{label: '32%', value: '32'}, {label: '68%', value: '68'}], presentation: '9 ans'},
                  {value: '10', label: '10 ans', options: [{label: '34%', value: '34'}, {label: '66%', value: '66'}], presentation: '10 ans'},
                  {value: '11', label: '11 ans', options: [{label: '35%', value: '35'}, {label: '65%', value: '65'}], presentation: '11 ans'},
                  {value: '12', label: '12 ans', options: [{label: '36%', value: '36'}, {label: '64%', value: '64'}], presentation: '12 ans'},
                  {value: '13', label: '13 ans', options: [{label: '37%', value: '37'}, {label: '63%', value: '63'}], presentation: '13 ans'},
                  {value: '14', label: '14 ans', options: [{label: '38%', value: '38'}, {label: '62%', value: '62'}], presentation: '14 ans'},
                  {value: '15', label: '15 ans', options: [{label: '40%', value: '40'}, {label: '60%', value: '60'}], presentation: '15 ans'},
                  {value: '16', label: '16 ans', options: [{label: '41%', value: '41'}, {label: '59%', value: '59'}], presentation: '16 ans'},
                  {value: '17', label: '17 ans', options: [{label: '42%', value: '42'}, {label: '58%', value: '58'}], presentation: '17 ans'},
                  {value: '18', label: '18 ans', options: [{label: '43%', value: '43'}, {label: '57%', value: '57'}], presentation: '18 ans'},
                  {value: '19', label: '19 ans', options: [{label: '44%', value: '44'}, {label: '56%', value: '56'}], presentation: '19 ans'},
                  {value: '20', label: '20 ans', options: [{label: '45%', value: '45'}, {label: '55%', value: '55'}], presentation: '20 ans'},
                ]
              }
            }
          }

          // Mock tableau de démemebrement Novapierre Allemagne 2
          if (item.id === '32989273-04a9-4e76-a546-dd5514febe80') {
            type = 'array_choice'
            attributes = {
              "label": "Tableau de démembrement viager",
              "readonly": false,
              "disabled": true,
              "required": true,
              "multiple": false,
              "options": {
                header: ['Année','Usufruit','Nue-propriété'],
                options: [
                  {value: '3', label: '3 ans', options: [{label: '10.5%', value: '10.5'}, {label: '89.5%', value: '89.5'}], presentation: '3 ans'},
                  {value: '4', label: '4 ans', options: [{label: '14.5%', value: '14.5'}, {label: '85.5%', value: '85.5'}], presentation: '4 ans'},
                  {value: '5', label: '5 ans', options: [{label: '17.5%', value: '17.5'}, {label: '82.5%', value: '82.5'}], presentation: '5 ans'},
                  {value: '6', label: '6 ans', options: [{label: '19.5%', value: '19.5'}, {label: '80.5%', value: '80.5'}], presentation: '6 ans'},
                  {value: '7', label: '7 ans', options: [{label: '22.5%', value: '22.5'}, {label: '77.5%', value: '77.5'}], presentation: '7 ans'},
                  {value: '8', label: '8 ans', options: [{label: '24.5%', value: '24.5'}, {label: '75.5%', value: '75.5'}], presentation: '8 ans'},
                  {value: '9', label: '9 ans', options: [{label: '27%', value: '27'}, {label: '73%', value: '73'}], presentation: '9 ans'},
                  {value: '10', label: '10 ans', options: [{label: '29.5%', value: '29.5'}, {label: '70.5%', value: '70.5'}], presentation: '10 ans'},
                  {value: '11', label: '11 ans', options: [{label: '31%', value: '31'}, {label: '69%', value: '69'}], presentation: '11 ans'},
                  {value: '12', label: '12 ans', options: [{label: '32.5%', value: '32.5'}, {label: '67.5%', value: '67.5'}], presentation: '12 ans'},
                  {value: '13', label: '13 ans', options: [{label: '34.5%', value: '34.5'}, {label: '65.5%', value: '65.5'}], presentation: '13 ans'},
                  {value: '14', label: '14 ans', options: [{label: '36.5%', value: '36.5'}, {label: '63.5%', value: '63.5'}], presentation: '14 ans'},
                  {value: '15', label: '15 ans', options: [{label: '39.5%', value: '39.5'}, {label: '60.5%', value: '60.5'}], presentation: '15 ans'},
                  {value: '16', label: '16 ans', options: [{label: '40.5%', value: '40.5'}, {label: '59.5%', value: '59.5'}], presentation: '16 ans'},
                  {value: '17', label: '17 ans', options: [{label: '41.5%', value: '41.5'}, {label: '58.5%', value: '58.5'}], presentation: '17 ans'},
                  {value: '18', label: '18 ans', options: [{label: '42.5%', value: '42.5'}, {label: '57.5%', value: '57.5'}], presentation: '18 ans'},
                  {value: '19', label: '19 ans', options: [{label: '43%', value: '43'}, {label: '57%', value: '57'}], presentation: '19 ans'},
                  {value: '20', label: '20 ans', options: [{label: '43.5%', value: '43.5'}, {label: '56.5%', value: '56.5'}], presentation: '20 ans'},
                ]
              }
            }
          }

          // Mock tableau de démemebrement Interpierre europe centrale (IEC)
          if (item.id === 'f6767282-5292-4054-a68c-3d212817c2bf') {
            type = 'array_choice'
            attributes = {
              "label": "Tableau de démembrement viager",
              "readonly": false,
              "disabled": true,
              "required": true,
              "multiple": false,
              "options": {
                header: ['Année','Usufruit','Nue-propriété'],
                options: [
                  {value: '3', label: '3 ans', options: [{label: '11%', value: '11'}, {label: '89%', value: '89'}], presentation: '3 ans'},
                  {value: '4', label: '4 ans', options: [{label: '15%', value: '15'}, {label: '85%', value: '85'}], presentation: '4 ans'},
                  {value: '5', label: '5 ans', options: [{label: '18%', value: '18'}, {label: '82%', value: '82'}], presentation: '5 ans'},
                  {value: '6', label: '6 ans', options: [{label: '20%', value: '20'}, {label: '80%', value: '80'}], presentation: '6 ans'},
                  {value: '7', label: '7 ans', options: [{label: '24%', value: '24'}, {label: '76%', value: '76'}], presentation: '7 ans'},
                  {value: '8', label: '8 ans', options: [{label: '25%', value: '25'}, {label: '75%', value: '75'}], presentation: '8 ans'},
                  {value: '9', label: '9 ans', options: [{label: '27.5%', value: '27.5'}, {label: '72.5%', value: '72.5'}], presentation: '9 ans'},
                  {value: '10', label: '10 ans', options: [{label: '31%', value: '31'}, {label: '69%', value: '69'}], presentation: '10 ans'},
                  {value: '11', label: '11 ans', options: [{label: '31.5%', value: '31.5'}, {label: '68.5%', value: '68.5'}], presentation: '11 ans'},
                  {value: '12', label: '12 ans', options: [{label: '32.5%', value: '32.5'}, {label: '67.5%', value: '67.5'}], presentation: '12 ans'},
                  {value: '13', label: '13 ans', options: [{label: '33.5%', value: '33.5'}, {label: '66.5%', value: '66.5'}], presentation: '13 ans'},
                  {value: '14', label: '14 ans', options: [{label: '34%', value: '34'}, {label: '66%', value: '66'}], presentation: '14 ans'},
                  {value: '15', label: '15 ans', options: [{label: '35%', value: '35'}, {label: '65%', value: '65'}], presentation: '15 ans'},
                  {value: '16', label: '16 ans', options: [{label: '36%', value: '36'}, {label: '64%', value: '64'}], presentation: '16 ans'},
                  {value: '17', label: '17 ans', options: [{label: '37%', value: '37'}, {label: '63%', value: '63'}], presentation: '17 ans'},
                  {value: '18', label: '18 ans', options: [{label: '38%', value: '38'}, {label: '62%', value: '62'}], presentation: '18 ans'},
                  {value: '19', label: '19 ans', options: [{label: '39%', value: '39'}, {label: '61%', value: '61'}], presentation: '19 ans'},
                  {value: '20', label: '20 ans', options: [{label: '40%', value: '40'}, {label: '60%', value: '60'}], presentation: '20 ans'},
                ]
              }
            }
          }

          // Mock tableau de démemebrement Interpierre France
          if (item.id === '8ac93c39-9d94-4b72-abae-96ab34d0db8d') {
            type = 'array_choice'
            attributes = {
              "label": "Tableau de démembrement viager",
              "readonly": false,
              "disabled": true,
              "required": true,
              "multiple": false,
              "options": {
                header: ['Année','Usufruit','Nue-propriété'],
                options: [
                  {value: '3', label: '3 ans', options: [{label: '12.5%', value: '12.5'}, {label: '87.5%', value: '87.5'}], presentation: '3 ans'},
                  {value: '4', label: '4 ans', options: [{label: '16%', value: '16'}, {label: '84%', value: '84'}], presentation: '4 ans'},
                  {value: '5', label: '5 ans', options: [{label: '20%', value: '20'}, {label: '80%', value: '80'}], presentation: '5 ans'},
                  {value: '6', label: '6 ans', options: [{label: '23.5%', value: '23.5'}, {label: '76.5%', value: '76.5'}], presentation: '6 ans'},
                  {value: '7', label: '7 ans', options: [{label: '26%', value: '26'}, {label: '74%', value: '74'}], presentation: '7 ans'},
                  {value: '8', label: '8 ans', options: [{label: '28.5%', value: '28.5'}, {label: '71.5%', value: '71.5'}], presentation: '8 ans'},
                  {value: '9', label: '9 ans', options: [{label: '30%', value: '30'}, {label: '70%', value: '70'}], presentation: '9 ans'},
                  {value: '10', label: '10 ans', options: [{label: '33%', value: '33'}, {label: '67%', value: '67'}], presentation: '10 ans'},
                  {value: '11', label: '11 ans', options: [{label: '34.5%', value: '34.5'}, {label: '65.5%', value: '65.5'}], presentation: '11 ans'},
                  {value: '12', label: '12 ans', options: [{label: '36%', value: '36'}, {label: '64%', value: '64'}], presentation: '12 ans'},
                  {value: '13', label: '13 ans', options: [{label: '37%', value: '37'}, {label: '63%', value: '63'}], presentation: '13 ans'},
                  {value: '14', label: '14 ans', options: [{label: '38%', value: '38'}, {label: '62%', value: '62'}], presentation: '14 ans'},
                  {value: '15', label: '15 ans', options: [{label: '39%', value: '39'}, {label: '61%', value: '61'}], presentation: '15 ans'},
                  {value: '16', label: '16 ans', options: [{label: '40%', value: '40'}, {label: '60%', value: '60'}], presentation: '16 ans'},
                  {value: '17', label: '17 ans', options: [{label: '41%', value: '41'}, {label: '59%', value: '59'}], presentation: '17 ans'},
                  {value: '18', label: '18 ans', options: [{label: '42%', value: '42'}, {label: '58%', value: '58'}], presentation: '18 ans'},
                  {value: '19', label: '19 ans', options: [{label: '43%', value: '43'}, {label: '57%', value: '57'}], presentation: '19 ans'},
                  {value: '20', label: '20 ans', options: [{label: '44%', value: '44'}, {label: '56%', value: '56'}], presentation: '20 ans'},
                ]
              }
            }
          }

          // Mock tableau de démembrement viager
          if (item.id === '027c92b4-a326-4cfd-8b83-e0e1efe1fb34' || item.id === 'a692795e-2119-4a6f-9fe8-7c4e74ef1f1f') {
            type = 'array_choice'
            attributes = {
              "label": "Tableau de démembrement viager",
              "readonly": false,
              "disabled": true,
              "required": true,
              "multiple": false,
              "options": {
                header: ['Age de l\'usufruitier','Usufruit','Nue-propriété'],
                options: [
                  {value: '20', label: 'Moins de 20 ans révolus', options: [{label: '90%', value: '90'}, {label: '10%', value: '10'}], presentation: 'Moins de 20 ans révolus'},
                  {value: '21_30', label: 'De 21 à 30 ans', options: [{label: '80%', value: '80'}, {label: '20%', value: '20'}], presentation: 'De 21 à 30 ans'},
                  {value: '31_40', label: 'De 31 à 40 ans', options: [{label: '70%', value: '70'}, {label: '30%', value: '30'}], presentation: 'De 31 à 40 ans'},
                  {value: '41_50', label: 'De 41 à 50 ans', options: [{label: '60%', value: '60'}, {label: '40%', value: '40'}], presentation: 'De 41 à 50 ans'},
                  {value: '51_60', label: 'De 51 à 60 ans', options: [{label: '50%', value: '50'}, {label: '50%', value: '50'}], presentation: 'De 51 à 60 ans'},
                  {value: '61_70', label: 'De 61 à 70 ans', options: [{label: '40%', value: '40'}, {label: '60%', value: '60'}], presentation: 'De 61 à 70 ans'},
                  {value: '71_80', label: 'De 71 à 80 ans', options: [{label: '30%', value: '30'}, {label: '70%', value: '70'}], presentation: 'De 71 à 80 ans'},
                  {value: '81_90', label: 'De 81 à 90 ans', options: [{label: '20%', value: '20'}, {label: '80%', value: '80'}], presentation: 'De 81 à 90 ans'},
                  {value: '91', label: 'Plus de 91 ans', options: [{label: '10%', value: '10'}, {label: '90%', value: '90'}], presentation: 'Plus de 91 ans'},
                ]
              }
            }
          }

          // Mock champs calculé Montant nu-propriétaire
          if (item.id === 'f64a415f-8a50-4978-ab97-2254352bf891') {
            type = 'calculate'
            calculate = '(<input_id>66bce220-e3a1-4284-82f0-2499a485e255_1</input_id> / 100) * (<input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id> * <input_id>06fcb274-9279-11eb-a8b3-0242ac130003</input_id>)'
          }

          // Mock champs calculé Taux Nu-propriétaire
          if (item.id === 'c415f4b9-cee3-4b21-9760-140f5e15a9dc') {
            type = 'calculate'
            calculate = '<input_id>66bce220-e3a1-4284-82f0-2499a485e255_1</input_id>'
          }

          // Mock champs calculé Montant Usufruitier
          if (item.id === 'aa7cb769-37c2-4171-b751-8e8f2475a3b6') {
            type = 'calculate'
            calculate = '(<input_id>66bce220-e3a1-4284-82f0-2499a485e255_0</input_id> / 100) * (<input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id> * <input_id>06fcb274-9279-11eb-a8b3-0242ac130003</input_id>)'
          }

          // Mock champs calculé Taux Usufruitier
          if (item.id === '85d55bd0-d3e5-4d90-af44-fa78acc4821e') {
            type = 'calculate'
            calculate = '<input_id>66bce220-e3a1-4284-82f0-2499a485e255_0</input_id>'
          }

          // Mock champs calculé Montant nu-propriétaire VIAGER
          if (item.id === 'fe1de194-97ea-4a59-b0a2-1efb9b9d3178') {
            type = 'calculate'
            calculate = '(<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34_1</input_id> / 100) * (<input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id> * <input_id>06fcb274-9279-11eb-a8b3-0242ac130003</input_id>)'
          }

          // Mock champs calculé Taux Nu-propriétaire VIAGER
          if (item.id === 'b912d642-2742-4688-89a3-999335156a34') {
            type = 'calculate'
            calculate = '<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34_1</input_id>'
          }

          // Mock champs calculé Montant Usufruitier VIAGER
          if (item.id === 'ec379366-c2f3-4945-8a93-32ac853f3625') {
            type = 'calculate'
            calculate = '(<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34_0</input_id> / 100) * (<input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id> * <input_id>06fcb274-9279-11eb-a8b3-0242ac130003</input_id>)'
          }

          // Mock champs calculé Taux Usufruitier VIAGER
          if (item.id === '7153becc-3f25-4598-a118-9cb7261bc468') {
            type = 'calculate'
            calculate = '<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34_0</input_id>'
          }

          // Mock Montant global
          if (item.id === '06fcb526-9279-11eb-a8b3-0242ac130003') {
            type = 'calculate'
            calculate = '<input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id> * <input_id>06fcb274-9279-11eb-a8b3-0242ac130003</input_id>'
          }

          // Mock Durée de démembrement
          if (item.id === '7904ee25-8ac6-499c-a179-119c3aae973d') {
            type = 'calculate'
            calculate = '<input_id>66bce220-e3a1-4284-82f0-2499a485e255</input_id>'
          }

          // Mock Age de l'usufruitier
          if (item.id === '0f968665-7797-4aaf-a9d1-1aa493ea1e01') {
            type = 'calculate'
            calculate = '<input_id>027c92b4-a326-4cfd-8b83-e0e1efe1fb34</input_id>'
          }

          // Mock H_1_montantunitaire_np_temp
          if (item.id === 'f64a415f-8a50-4951-ab97-2254342bf891') {
            type = 'calculate'
            calculate = '(<input_id>c415f4b9-cee3-4b21-9760-140f5e15a9dc</input_id> / 100) * <input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id>'
          }

          // Mock H_1_montantunitaire_usu_temp
          if (item.id === 'f64a415f-8a50-5178-ab97-2254352bf891') {
            type = 'calculate'
            calculate = '(<input_id>85d55bd0-d3e5-4d90-af44-fa78acc4821e</input_id> / 100) * <input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id>'
          }

          // Mock H_1_montantunitaire_np_viag
          if (item.id === 'f12a415f-8a50-4951-ab97-2254342bf891') {
            type = 'calculate'
            calculate = '(<input_id>b912d642-2742-4688-89a3-999335156a34</input_id> / 100) * <input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id>'
          }

          // Mock H_1_montantunitaire_usu_viag
          if (item.id === 'f13a415f-8a50-5178-ab97-2254352bf891') {
            type = 'calculate'
            calculate = '(<input_id>7153becc-3f25-4598-a118-9cb7261bc468</input_id> / 100) * <input_id>06fcafa4-9279-11eb-a8b3-0242ac130003</input_id>'
          }

          if (item.id === '380a8c82-1efb-4339-a5d0-bff7de26112d'
              || item.id === 'f3fbabe3-a187-4d8a-b401-5ca1f3e3b7d4'
              || item.id === 'cef08ffb-f1ea-4a12-917b-1ca13aa22bd5'
              || item.id === '66e39df8-aa9d-4534-9729-3e0f07bcd849') {
            type = 'input_iban'
          }

          // Mock Nombre de parts ( en toutes lettres)
          if (item.id === '06fcb332-9279-11eb-a8b3-0242ac130003') {
            type = 'convert_number_to_word'
            reference = '06fcb274-9279-11eb-a8b3-0242ac130003'
          }

          // Mock Montant global : ( en toutes lettres)
          if (item.id === '06fcb5ee-9279-11eb-a8b3-0242ac130003') {
            type = 'convert_number_to_word'
            reference = '06fcb526-9279-11eb-a8b3-0242ac130003'
          }

          // Mock H_montant-NP-dem-temp-toutes-lettres : ( en toutes lettres)
          if (item.id === '145b9e20-80b6-476b-ad4a-27a03b048abc') {
            type = 'convert_number_to_word'
            reference = 'f64a415f-8a50-4978-ab97-2254352bf891'
          }

          // Mock H_montant-NP-dem-viager-toutes-lettres : ( en toutes lettres)
          if (item.id === '142b9e20-80b6-476b-ad4a-27a03b048abc') {
            type = 'convert_number_to_word'
            reference = 'fe1de194-97ea-4a59-b0a2-1efb9b9d3178'
          }

          // @TODO: Mock final step
          if (item.id === '8a924722-bac6-4b3d-bfec-5080a0b4d8a1') {
            type = 'presentation'
            reference = '693eb07f-b309-4677-90ab-1f9b39d1e92d'
          }

          if (item.id === 'a3a12574-06e4-4a87-bfab-ed0de4c69ba2') {
            type = 'presentation'
            reference = '06fc90c8-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === '1ee4f512-ca1e-45b1-b712-0ec08699437b') {
            type = 'presentation'
            reference = '06fc8e84-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === '8a924722-bac6-4b3d-bfec-5080a0b4d8a1') {
            type = 'presentation'
            reference = '693eb07f-b309-4677-90ab-1f9b39d1e92d'
          }

          if (item.id === 'a3a12574-06e4-4a87-bfab-ed0de4c69ba2') {
            type = 'presentation'
            reference = '06fc90c8-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === '1ee4f512-ca1e-45b1-b712-0ec08699437b') {
            type = 'presentation'
            reference = '06fc8e84-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === '93b40cc0-a14a-46f7-9cdc-35d2937c8183') {
            type = 'presentation'
            reference = '6033381f-e075-42fc-a234-0fcee9fc5f6c'
          }

          if (item.id === '4647b8a6-7549-4e7e-9f65-1da7245c9e13') {
            type = 'presentation'
            reference = '06fca5c2-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === 'dbb5395d-cc20-4381-b7be-648c667f3d77') {
            type = 'presentation'
            reference = '06fcae78-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === 'c8c46ce8-088a-4e40-8946-08350cd94ae4') {
            type = 'presentation'
            reference = '06fcb274-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === 'de16fc41-67f0-4726-9bdf-b417561e420e') {
            type = 'presentation'
            reference = '06fcb526-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === 'ed35c332-b76e-4d15-b7ab-ee38d16b5b17') {
            type = 'presentation'
            reference = 'd35597e6-06b2-4aac-aade-e7e66e0eb17b'
          }

          if (item.id === 'f5293e3e-55c5-48d7-a57a-2b3a84562352') {
            type = 'presentation'
            reference = 'c415f4b9-cee3-4b21-9760-140f5e15a9dc'
          }

          if (item.id === 'e66f2554-a3d2-4671-880f-56fbb894d86d') {
            type = 'presentation'
            reference = '85d55bd0-d3e5-4d90-af44-fa78acc4821e'
          }

          if (item.id === 'affe1c89-6add-4b55-832d-7a6d889388eb') {
            type = 'presentation'
            reference = 'aa7cb769-37c2-4171-b751-8e8f2475a3b6'
          }

          if (item.id === 'f5212254-296a-4692-bb69-56925a1dfa24') {
            type = 'presentation'
            reference = 'f64a415f-8a50-4978-ab97-2254352bf891'
          }

          if (item.id === 'ec0c250b-a61d-46d4-b484-c064459eb810') {
            type = 'presentation'
            reference = '151908ad-c89d-4657-81a7-f0730cc69ae6'
          }

          if (item.id === '75ea234d-0db6-41d5-ac1c-381b90cf1f8d') {
            type = 'presentation'
            reference = '7904ee25-8ac6-499c-a179-119c3aae973d'
          }

          if (item.id === '6b6a9c26-a178-4ae2-919a-7e6929d4c149') {
            type = 'presentation'
            reference = '06fcbe54-9279-11eb-a8b3-0242ac130003'
          }

          if (item.id === 'e705965a-0428-4d89-9fea-0ae36da7fd12') {
            type = 'presentation'
            reference = 'fe1de194-97ea-4a59-b0a2-1efb9b9d3178'
          }

          if (item.id === '80a7f604-3bde-413b-8fde-b5cfa48e6242') {
            type = 'presentation'
            reference = 'ec379366-c2f3-4945-8a93-32ac853f3625'
          }

          if (item.id === '73efdfe1-fa54-4200-bb7e-b11960c1ed3e') {
            type = 'presentation'
            reference = 'b912d642-2742-4688-89a3-999335156a34'
          }

          if (item.id === '6716fa5a-f474-4441-b933-f9c24428dd57') {
            type = 'presentation'
            reference = '7153becc-3f25-4598-a118-9cb7261bc468'
          }

          if (item.id === 'f0980926-dd3a-4838-b2c0-e6bf402733d1') {
            condition = {
              'rules'           : [
                {
                  'type'    : 'input',
                  'input_id': '06fcacfc-9279-11eb-a8b3-0242ac130003',
                  'value'   : 'Oui'
                },
                {
                  "type": "input",
                  "input_id": "06fcb526-9279-11eb-a8b3-0242ac130003",
                  "value": 100000,
                  "operator": "greater_than"
                }
              ],
              'logical_operator': 'or', 'actions': {'required': false, 'disabled': false}
            }
          }

          if (item.id === '36401f4c-1908-420b-a8ff-f64c02433c0f') {
            type = 'button_docusign_iframe'
            attributes = {
              "required": false,
              "readonly": false,
              "disabled": false,
              "label": "Signer le document",
              "docusignId": "80c9da5c-2517-47d3-9239-48b5db040aef",
              "help": null,
              "errorMessage": null,
              "defaultValue": null
            }
          }

          if (item.id === '78a56b2b-275d-4781-b0b9-e1c00e55992f') {
            type = 'button_docusign_iframe'
            attributes = {
              "required": false,
              "readonly": false,
              "disabled": false,
              "label": "Signer le document",
              "docusignId": "7cf81483-ad33-49e3-9e32-c8e72426b293",
              "help": null,
              "errorMessage": null,
              "defaultValue": null
            }
          }

          if (item.id === '78979a0d-58be-4b03-95a4-02aa57317f8b') {
            type = 'button_print'
            attributes = {
              "required": false,
              "readonly": false,
              "disabled": false,
              "label": "Imprimer le document",
              "help": null,
              "errorMessage": null,
              "defaultValue": 37
            }
          }

          if (item.id === '071839f8-cb78-40d3-83f4-473354607f15') {
            type = 'button_print'
            attributes = {
              "required": false,
              "readonly": false,
              "disabled": false,
              "label": "Imprimer le document",
              "help": null,
              "errorMessage": null,
              "defaultValue": 36
            }
          }

          // Création, Authorisation du prospect
          if (item.id === 'e176f745-ec12-49d3-92aa-0a8e5b63986c') {
            type = 'button_create_prospect'
          }

          return new Element(
            item.id,
            item.name,
            type,
            item.css_classes,
            attributes,
            condition,
            calculate,
            reference
          )
        })
      })
      .catch(() => null);
  }
}




